.gold-prices {
  padding: 20px;
}

.gold-prices .price-card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.gold-prices .card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #eee;
  padding: 15px 20px;
}

.gold-prices .card-header h5 {
  color: #333;
  font-weight: 600;
}

.gold-prices .highlight {
  background-color: #fff9c4;
  transition: background-color 0.5s ease-in-out;
}

.gold-prices .table {
  margin: 0;
}

.gold-prices .table th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #eee;
  color: #666;
  font-weight: 600;
  padding: 12px 20px;
}

.gold-prices .table td {
  padding: 12px 20px;
  border-bottom: 1px solid #eee;
}

.gold-prices .buy-price {
  color: #2e7d32;
  font-weight: 500;
}

.gold-prices .sell-price {
  color: #c62828;
  font-weight: 500;
}

.gold-prices .text-muted {
  font-size: 0.9rem;
}

.gold-prices h1 {
  margin-top: 20px;
  font-size: 2.5rem;
  color: #343a40;
  text-align: center;
  text-shadow: 1px 1px 2px #000;
}

/* Responsive styles */
@media (max-width: 768px) {
  .gold-prices {
    padding: 10px;
  }

  .gold-prices .table th,
  .gold-prices .table td {
    padding: 10px;
    font-size: 0.9rem;
  }

  .gold-prices .card-header {
    padding: 12px 15px;
  }

  .gold-prices .card-header h5 {
    font-size: 1rem;
  }

  .gold-prices .table th:nth-child(4),
  .gold-prices .table td:nth-child(4) {
    display: none;
  }
}